// Global branded styling. App specific styling should NOT go in this file

// PrimeNG overrides
.p-card {
  border-radius: 20px;
  margin-top: 10px; // Allow room for box-shadow
  margin-bottom: 10px; // Allow room for box-shadow
}

// Bootstrap overrides
.card {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
