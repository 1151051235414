// Original
// p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
//   @include invalid-input();
// }

// Custom invalid input handling
@include input-form-validation('p-password.ng-invalid') {
  // .p-password class doesn't show up until you click into password field, so excluding from selector
  & /*> .p-password >*/ .p-inputtext {
    @include invalid-input();
  }
}

.p-password-panel {
  padding: $panelContentPadding;
  background: $panelContentBg;
  color: $panelContentTextColor;
  border: $overlayContentBorder;
  box-shadow: $inputOverlayShadow;
  border-radius: $borderRadius;

  .p-password-meter {
    margin-bottom: $inlineSpacing;
    background: $passwordMeterBg;

    .p-password-strength {
      &.weak {
        background: $passwordWeakBg;
      }

      &.medium {
        background: $passwordMediumBg;
      }

      &.strong {
        background: $passwordStrongBg;
      }
    }
  }
}
