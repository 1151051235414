/**
* Main theme variables file
*/

/**
  * Figma Colors
  * Source: https://www.figma.com/file/7mb6RCLyVBNvV0UcKoHyCg/RealtyBid-Design-Library?node-id=2%3A71
 */
$primaryBlue: #3570e6;
$primaryBlueDark: #1955cc;
$primaryBlueLight: #edf2fd;

$primaryRed: #db3747;
$primaryRedDark: #bd2f3d;
$primaryRedLight: #fbe9eb;

$severitySuccess: #689f38;
$severityDanger: #d32f2f;

$gray-600: #1f2225;
$gray-500: #57585b;
$gray-400: #737578;
$gray-300: #a7a9ac;
$gray-200: #d1d2d3;
$gray-100: #e6e7e8;
$gray-50: #f2f2f3;
$gray-0: #ffffff;

$blue-600: #002c5d;
$blue-500: #205f92;
$blue-400: #4a7ba1;
$blue-300: #799fbd;
$blue-200: #a6bed2;
$blue-100: #d2dfe9;
$blue-50: #eef3f7;

$red-500: #b71c1c;
$red-400: #d32f2f;
$red-300: #f44336;
$red-200: #ef5350;
$red-100: #ef9a9a;
$red-50: #ffebee;

$green-500: #2e7d32;
$green-400: #43a047;
$green-300: #66bb6a;
$green-200: #a5d6a7;
$green-100: #c8e6c9;
$green-50: #e8f5e9;

$yellow-500: #f57f17;
$yellow-400: #fbc02d;
$yellow-300: #ffeb3b;
$yellow-200: #fff176;
$yellow-100: #fff59d;
$yellow-50: #fffde7;

$boxShadowLight: 0px 3px 10px rgba(0, 0, 0, 0.15);
$boxShadowMedium: 0px 3px 20px rgba(0, 0, 0, 0.15);
$boxShadowHeavy: 0px 5px 30px rgba(0, 0, 0, 0.2);

// Theme Specific Variables
$primaryColor: $primaryBlue;
$primaryDarkColor: $primaryBlueDark;
$primaryDarkerColor: $primaryBlueDark;
$primaryTextColor: #ffffff;

// Usage: color: --nts-grey;
$colors: (
  // 'blue': #2196f3,
  'green': #4caf50,
  'yellow': #fbc02d,
  'cyan': #00bcd4,
  'pink': #e91e63,
  'indigo': #3f51b5,
  'teal': #009688,
  'orange': #f57c00,
  'bluegray': #607d8b,
  'purple': #9c27b0,
  // 'red': #ff4032,
  'grey': #eff3f6,
  'blue': $primaryBlue,
  'red': $primaryRed,
  'gray-600': $gray-600,
  'gray-500': $gray-500,
  'gray-400': $gray-400,
  'gray-300': $gray-300,
  'gray-200': $gray-200,
  'gray-100': $gray-100
);

/* If loading scss files from the assets dir, this is the path */
// $asset-slug: ''; // For root
$asset-slug: '/realtybid'; // For subdirs

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';
@import './variables/_breakpoints';

// Global SCSS Variables
// Usage: color: --primary-color;
:root {
  // Theme Colors
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  // Text Colors
  --text-color: #{$textColor};
  --text-color-secondary: #{$textSecondaryColor};
  // Spacing/padding
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  // Global font families
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  // Less important stuff
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: #{$maskBg};
  --focus-ring: #{$focusShadow};
  // Add colors
  @include convert($colors);
  // Add breakpoints
  @include convert($breakpoints);
}
