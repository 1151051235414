@mixin footnote {
  font-size: 0.75rem;
  line-height: 18px;
  letter-spacing: 0.25px;
  &.heavy {
    font-weight: 700;
  }
}

@mixin small {
  font-size: 0.875rem !important; // 14px
  line-height: 22px !important;
}

@mixin subtitle {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 22px;
  letter-spacing: 0.15px;
}

@mixin body-large {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

@mixin h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 44px;
  letter-spacing: -0.3px;
  color: $gray-500;
  @include lg-up() {
    font-size: 2.25rem;
  }
}

@mixin h2 {
  font-family: 'Inter', sans-serif;
  font-size: 1.875rem;
  line-height: 38px;
  letter-spacing: -0.2px;
  color: $textColor;
}

@mixin h3 {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  line-height: 30px;
  letter-spacing: -0.1px;
  color: $textColor;
}

@mixin h4 {
  font-family: 'Inter', sans-serif;
  font-size: 1.3125rem;
  line-height: 28px;
  color: $textColor;
}

@mixin h5 {
  font-family: 'Inter', sans-serif;
  font-size: 1.125rem;
  line-height: 26px;
  letter-spacing: 0.1px;
  color: $textColor;
}

@mixin h6 {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $textColor;
}
