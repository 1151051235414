.p-radiobutton {
  .p-radiobutton-box {
    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: $gray-400;
    }
  }
}

p-radiobutton.with-background {
  padding: 0 0.5625rem;
  min-width: 180px;
  border-radius: $borderRadius;
  &:hover {
    background: $primaryBlueLight;
  }
  .p-radiobutton-label {
    padding: 0.875rem 0;
    margin-right: $inlineSpacing;
  }
}
