#manage-property {
  #nts-wizard {
    .field-short {
      width: 150px;
    }
    textarea {
      width: 100%;
      height: 300px;
    }
    .grey {
      color: #666666;
    }
    p {
      color: #1f2225;
    }
    h1 {
      margin-bottom: 2rem;
      font-size: 24px;
    }
    .nts-wizard-content,
    .alert {
      margin-bottom: 2rem;
    }
    .red {
      color: red;
    }
    .p-dropdown {
      width: 100%;
    }
    .dropdown-label {
      top: -27px;
      font-size: 16px;
      opacity: 1;
    }
  }
}
