.p-dynamic-dialog {
  .p-dialog-footer {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -0.571em;
    margin-top: 2em;
    border-top: 1px solid #d1d2d3;
    @include md-down {
      border: 0;
    }
  }
}

.p-dialog {
  @include md-down {
    &.dialog-logout,
    &.dialog-fs {
      position: absolute;
      bottom: 0;
      top: 0;
      max-height: initial !important;
      left: 0;
      right: 0;
      border-radius: 0;
      .p-dialog-content {
        align-items: initial;
      }
    }
    .p-dialog {
      border-radius: 0 !important;
    }
  }

  .p-dialog-header {
    @include h4;
    justify-content: center;
    padding: 1.75rem 0 1.5rem 0;
    border-bottom: 1px solid #d1d2d3;
    @include md-down {
      padding: 1.75rem 0 1.5rem 0;
      border: 0;
      border-radius: 0 !important;
    }
    .p-dialog-title {
      width: 100%;
      padding: 0 2rem;
      text-align: left;
      @include md-down {
        text-align: center;
        word-wrap: break-word;
      }
    }
    .p-dialog-header-icons {
      position: absolute;
      right: 1.25rem;

      top: 23px;
      @include md-down {
        right: 0.5rem;
      }
      .pi {
        font-size: 1.125rem;
        font-weight: bold;
      }
    }
  }
  .p-dialog-content {
    padding: 1.5rem 1.5rem 0.6rem 1.5rem;
    p {
      margin-bottom: 1rem;
    }
  }
  .p-dialog-footer {
    @include md-down {
      padding: 1.25rem;
    }
  }
}
