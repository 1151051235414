.p-carousel {
  .p-carousel-content {
    .p-carousel-prev,
    .p-carousel-next {
      width: 2.5rem;
      height: 2.5rem;
      .pi {
        font-size: 1.5rem;
      }
      &:enabled:hover,
      &:enabled:hover {
        background: transparent;
      }
    }
    .p-carousel-item {
      display: flex;
      justify-content: center;
    }
  }
}
