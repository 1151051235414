.p-tabview {
  .p-tabview-nav {
    li {
      flex: 1;
      .p-tabview-nav-link {
        justify-content: center;
        @include h6;
        color: $textSecondaryColor;
      }
    }
  }
  .p-tabview-nav-btn.p-link {
    &:focus {
      box-shadow: none;
    }
  }
}

@include md-down {
  .p-tabview {
    .p-tabview-nav {
      border: none;
      li:first-child {
        margin-right: 1rem;
      }
      li {
        flex: 0;
        .p-tabview-nav-link {
          @include body-large;
          color: $textColor;
          font-weight: 400;
          margin: 0;
          border: none;
          border-radius: $borderRadius;
          padding: 0.75rem 0.75rem;
        }
        &:not(.p-highlight):not(.p-disabled):hover {
          .p-tabview-nav-link {
            border: none;
            color: $textColor;
          }
        }
        &.p-highlight {
          .p-tabview-nav-link {
            font-weight: bold;
            border: 1px solid $primaryBlueDark;
          }
        }
      }
    }
  }
}
