.p-checkbox {
  .p-checkbox-box {
    border-radius: 4px;
    .p-checkbox-icon {
      margin-left: 0.5px;
      transform: scaleY(0.9);
      font-weight: 600;
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $gray-400;
        }
      }
    }
  }
}
p-checkbox.with-background {
  padding: 0 0.5625rem;
  min-width: 180px;
  border-radius: $borderRadius;
  &:hover {
    background: $primaryBlueLight;
  }
  .p-checkbox-label {
    padding: 0.875rem 0;
    margin-right: $inlineSpacing;
  }
}

/* Use when you need HTML label content. Otherwise just use the p-checkbox [label] input
 * and the label will be generated for you.
 * e.g.
 * <label class="checkbox-label"><p-checkbox></p-checkbox> Label Text</label>
 */
.checkbox-label {
  display: flex;
  align-items: center;
  p-checkbox {
    margin-right: $inlineSpacing;
  }
  &.with-background {
    padding: 0.875rem 0.5625rem;
    min-width: 180px;
    border-radius: $borderRadius;
    &:hover {
      background: $primaryBlueLight;
    }
  }
}
