.p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite !important;
}

@keyframes custom-progress-spinner-color {
  100%,
  0% {
    stroke: $primaryColor;
  }
}
