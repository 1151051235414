@import '/src/styles/mixins';
@import '/src/styles/theme/variables';

@mixin marker {
  background-color: #fff;
  box-shadow: $boxShadowLight;
  border-radius: 20px;
  text-align: center;
  padding: 4px 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border: 2px solid #fff;
  &.active {
    background-color: #1955cc;
    color: #fff !important;
  }
  &:hover {
    background-color: #fff;
    background: #eaeaea;
  }
}

.marker {
  @include marker;
}

.marker-with-heart {
  @include marker;
  &::before {
    content: '\2764';
    color: red;
    margin-right: 6px;
    font-size: 16px;
    font-weight: bolder;
  }
}

/* Info Window*/
.gm-style .gm-style-iw-c {
  background: initial;
  box-shadow: initial;
  padding: 0;
  top: 15px !important;
  .gm-style-iw-d {
    overflow: hidden !important;
    padding: 15px;
    app-property-card {
      margin: 0;
    }
  }
  button.gm-ui-hover-effect {
    top: 5px !important;
    right: 5px !important;
    background: #fff !important;
    opacity: 1;
    border-radius: 50%;
    box-shadow: $boxShadowLight;
    width: 21px !important;
    height: 21px !important;
    &:hover {
      background: #e7e7e7 !important;
    }
    span {
      background-color: #000;
      position: relative;
      left: -4px;
      top: -4px;
    }
  }
}
