// Set all image containers to fill parent
.p-galleria,
.p-galleria-content,
p-galleriaitem,
.p-galleria-item-wrapper {
  height: 100%;
  width: 100%;
}

.p-galleria {
  .p-galleria-close {
    z-index: 1;
  }
  .p-galleria-indicator button {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  .p-highlight button {
    background: $primaryBlue !important;
  }

  .p-galleria-item-nav {
    transform: translate(0, -50%);
    opacity: 0.5;
    z-index: 5;
    &:hover {
      opacity: 0.9;
    }
  }

  &.p-galleria-indicator-onitem {
    .p-galleria-indicators {
      z-index: 10;
      .p-galleria-indicator {
        &.p-highlight {
          button {
            background: white;
          }
        }
      }
    }
  }
}
