/* You can add global styles to this file, and also import other style files */
// Base CSS Size: 619k
// New Size: 315k

// Bootstrap
// Option 1: Import only parts of Bootstrap - 99k
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/utilities/api';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/helpers';

// Components
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/type';

// Option 2: Import all of Bootstrap - 154k
// @import '~bootstrap/scss/bootstrap';

// Font Awesome Free 5
// @import '~@fortawesome/fontawesome-free/css/all.css'; // 57k

// Global tools/mixins
@import 'styles/mixins';

// PrimeNG
@import '~primeicons/primeicons.css'; // 500k including font files
@import '~primeng/resources/primeng.min.css'; // 7k
// Prime NG Flex grid, kinda sucks
// @import '~primeflex/primeflex.css'; // 236k
// Option 1: Pre-built PrimeNG theme
// @import '~primeng/resources/themes/nova/theme.css'; // 134k
// Option 2: Custom theme using PrimeNG Theme Designer
@import 'styles/theme/theme';

// Import app styleguide
@import 'styles/styleguide/styleguide';
