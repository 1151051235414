.m-16 {
  margin: 1rem;
}

.mt-5 {
  margin-top: 0.313rem;
}

.mt-10 {
  margin-top: 0.625rem;
}

.mt-15 {
  margin-top: 0.938rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mt-24 {
  margin-top: 1.5rem;
}

.mt-32 {
  margin-top: 2rem;
}

.mr-5 {
  margin-right: 0.313rem;
}

.mr-10 {
  margin-right: 0.625rem;
}

.mr-15 {
  margin-right: 0.938rem;
}

.mr-16 {
  margin-right: 1rem;
}

.mr-20 {
  margin-right: 1.25rem;
}

.mr-24 {
  margin-right: 1.5rem;
}

.mb-5 {
  margin-bottom: 0.313rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.mb-15 {
  margin-bottom: 0.938rem;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.mb-32 {
  margin-bottom: 2rem;
}

.mb-64 {
  margin-bottom: 4rem;
}

.mb-16 {
  margin-bottom: 1rem !important;
}

.ml-5 {
  margin-left: 0.313rem !important;
}

.ml-10 {
  margin-left: 0.625rem;
}

.ml-15 {
  margin-left: 0.938rem;
}

.ml-16 {
  margin-left: 1rem;
}

.ml-24 {
  margin-left: 1.5rem;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-start {
  justify-content: start;
}

.flex {
  display: flex;
}

.nowrap {
  white-space: nowrap;
}
