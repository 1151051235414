.p-dropdown {
  .p-dropdown-label {
    border: none !important; // Undo p-textinput border
    margin: unset !important; // Undo p-textinput border
  }
  &:not(.p-disabled) {
    @include active-input;

    &:hover,
    &:focus {
      .p-dropdown-trigger {
        margin-right: -2px; // Offset for border width increase :(
      }
    }
  }
}
