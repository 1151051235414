.gray-400 {
  color: $gray-400 !important;
}
.gray-500 {
  color: $gray-500 !important;
}
.gray-600 {
  color: $gray-600 !important;
}
.yellow-500 {
  color: $yellow-500 !important;
}
.text-color {
  color: $textColor !important;
}
.text-secondary {
  color: $textSecondaryColor !important;
}
.primary-color {
  color: $primaryColor !important;
}
.primary-blue-dark {
  color: $primaryBlueDark !important;
}
