// Global branded styling. App specific styling should NOT go in this file
@import '/src/styles/theme/variables/general';
@import './typography_mixins';

.footnote {
  @include footnote;
}
small,
.small {
  @include small;
}
.small-md {
  @include md-up {
    @include small;
  }
}
.subtitle {
  @include subtitle;
}

html,
body {
  @include body-large;
  color: $textColor;
}

.body-bold {
  @include body-large;
  color: $textColor;
  font-weight: 700;
}

.large-md {
  @include md-up {
    @include body-large;
  }
}
// All header styles
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  margin-bottom: 0.75rem;
  small {
    font-size: 70%;
  }
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

h5,
.h5 {
  @include h5;
}

h6,
.h6 {
  @include h6;
}

p {
  margin-bottom: 1rem;
}

.strong {
  font-weight: bold !important;
}
.medium-bold {
  font-weight: 500 !important;
}
.em {
  font-style: italic;
}
.go-back {
  line-height: 3rem;
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
