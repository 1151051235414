// Original
// p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
//     @include invalid-input();
// }

// Custom invalid input handling
@include input-form-validation('p-inputmask.ng-invalid') {
  & > .p-inputtext {
    @include invalid-input();
  }
}
