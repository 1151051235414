$input-padding: 0.75rem;
$input-padding-with-button: 5rem;
.p-inputgroup {
  &.with-button {
    position: relative;
    .p-inputtext {
      height: 100%;
      width: 100%;
      transition: none;
      padding-left: $input-padding;
      padding-right: $input-padding-with-button;
      border-radius: $borderRadius;
      &:enabled:hover,
      &:enabled:focus {
        border: 2px solid $gray-400;
        margin: -2px -2px -2px -1px;
        height: calc(100% + 4px);
        width: calc(100% + 3px);
      }
    }
    .p-button {
      position: absolute;
      right: 0;
      z-index: 1;
      height: 100%;
      width: auto;
      aspect-ratio: 1;
      padding: 0;
      border-radius: calc(#{$borderRadius} - 2px);
    }
  }
}
