.p-datatable {
  font-size: 14px;
  color: $textSecondaryColor;
  .p-datatable-thead > tr > th {
    &:last-child {
      border-right: none;
    }
  }
  .p-sortable-column {
    p-sorticon {
      margin-left: auto;
      .p-sortable-column-icon {
        font-size: 0.8rem;
        padding-left: $inlineSpacing;
        margin-left: 0;
      }
    }
  }
  .p-datatable-tbody > tr:hover {
    background: $primaryBlueLight;
  }
}
