$panel-border-radius: 20px;
.p-panel {
  .p-panel-header {
    border-top-right-radius: $panel-border-radius;
    border-top-left-radius: $panel-border-radius;
    .p-panel-header-icon {
      .pi-minus::before {
        content: '\e903'; // Chevron up
      }
      .pi-plus::before {
        content: '\e902'; // Chevron down
      }
    }
  }
  .p-panel-content {
    border-bottom-right-radius: $panel-border-radius;
    border-bottom-left-radius: $panel-border-radius;
  }
}
