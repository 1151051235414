html,
body {
  overscroll-behavior: none;
}

body {
  overflow: overlay;
}
.p-error {
  display: block;
  margin: 0.25rem 0 0 0;
}
#nts-file-uploader .nts-file-uploader {
  display: flex;
  flex-direction: column-reverse;
  .nts-file-uploader-label {
    background-color: #f6f8fe;
    border: 2px dashed #a5bff5;
    border-radius: 12px;
    margin-bottom: 2rem;
  }
  .nts-file-row {
    border: 1px solid #d1d2d3;
    border-radius: 5px;
    margin-bottom: 1rem;
  }
  .nts-file-uploader-icon {
    color: #a5bff5;
    content: initial !important;
  }
  .nts-remove .p-button {
    background: #fff;
    border: 0;
    color: #737578;
  }
  .nts-can-add-additional .nts-file-icon-upload-wrapper {
    display: initial;
  }
  .nts-file-uploader-icon {
    height: initial;
  }

  .pi.pi-cloud-upload {
    font-size: 9rem;
    color: #a5bff5;
  }
  .nts-file-uploader-title {
    color: #9ea6ae;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    strong {
      color: #3570e6;
    }
  }
}
