.p-toast {
  width: unset !important;
  max-width: 600px;
  margin: 0 2rem;
  z-index: 1070; // bootstrap popover z-index

  .p-toast-message {
    border-radius: 4px;

    .p-toast-icon-close {
      color: $gray-500 !important;
    }

    &.p-toast-message-success {
      .pi-check:before {
        content: '\e90a'; // pi-check-circle
      }
    }

    &.p-toast-message-error {
      .pi-times-circle:before {
        content: '\e989'; // pi-exclamation-circle
      }
    }
  }
}

.no-summary {
  .p-toast-message {
    .p-toast-message-content {
      align-items: center;
      .p-toast-detail {
        margin: 0;
      }
    }
  }
}
