// Global branded styling. App specific styling should NOT go in this file

// Buttons
button {
  cursor: pointer;
}

.p-button {
  font-weight: 500;
  &.active,
  &.enabled {
    background-image: linear-gradient(to top, #ad0000 0%, #ff4343 100%);
    color: #fff;
    border: 1px solid red;
  }

  &.btn-waiting {
    .fa {
      display: none;
    }
    &:before {
      font-family: FontAwesome;
      content: '\f110';
      animation: fa-spin 2s infinite linear;
      display: inline-block;
      position: relative;
      left: -3px;
    }
  }
  &.btn-waiting-after {
    .fa {
      display: none;
    }
    &:after {
      font-family: FontAwesome;
      content: '\f110';
      animation: fa-spin 2s infinite linear;
      display: inline-block;
      position: relative;
      left: -3px;
    }
  }

  &[disabled] {
    opacity: $disabledOpacity;
  }

  &.p-button-sm {
    font-size: 1rem;
    padding: 0.4375rem 1rem;
  }
  .p-button-icon {
    padding-left: 1px;
    font-size: 0.8rem;
  }

  .p-button-icon-left {
    margin-right: 0.3rem;
  }

  .p-button-icon-right {
    margin-left: 0.3rem;
  }

  .p-button-icon-bottom {
    margin-top: 0.3rem;
  }

  .p-button-icon-top {
    margin-bottom: 0.3rem;
  }

  &.stack {
    border: 0;
    box-shadow: none !important;
    border-radius: 0;
    padding: 0.75rem 1rem !important;
    text-align: left;
    &:disabled {
      color: $textSecondaryColor;
      opacity: 1;
    }
  }
}
.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  padding: 0.625rem 1.25rem;
  &.p-button-sm {
    padding: 0.4375rem 1rem;
  }
  &:enabled:hover {
    box-shadow: $secondaryButtonFocusShadow;
  }
}

.p-button.p-button-link {
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.15px;
  &.p-button-sm {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.15px;
  }
  &.p-button-lg {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: normal;
  }
}

body #CybotCookiebotDialog {
  div,
  h2 {
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #1f2225;
  }

  .CybotCookiebotDialogBodyButton {
    @extend .p-button;
    height: initial;
    width: initial !important;
  }
}
