.modal-wizard {
  .p-dialog-content {
    display: flex;
    overflow: hidden !important;

    .card {
      padding: 0 1.25rem;
      height: 100%;
      box-shadow: none;
      border: none;

      @include lg-down {
        padding: 0;
      }

      .card-header {
        padding: 0;
      }
      .card-block {
        overflow-y: auto;
        height: 100%;
      }
      &:not(.unsticky-footer) .card-block {
        margin-right: -3rem;
        padding-right: 3rem;
      }
      &.unsticky-footer {
        margin-right: -1.75rem;
        padding-right: 2.75rem;
        overflow-y: auto;

        .card-block {
          overflow: unset !important;
          height: unset !important;
        }
      }
    }

    .content-footer {
      margin-top: 1.5rem;
      padding: 1.5rem 0 0 0 !important;
      border-top: 1px solid var(--surface-border);
      background-color: var(--surface-a);

      .extra-content {
        font-family: 'Inter';
        color: $gray-500;

        ::ng-deep p {
          margin: 0 !important;
        }
        &.top-content {
          margin-bottom: 1.5rem;
        }
        &.bottom-content {
          margin-top: 1.5rem;
        }
      }
      button {
        margin-left: 1rem;
        min-width: 100px;
      }
    }
  }
}

/* Turn an ordinary modal into a fullscreen takeover on mobile*/
@include md-down {
  .p-dialog.full-screen {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100% !important;
    height: 100vh;
    max-height: initial !important;
    z-index: 9999;
    border-radius: 0px;
    .p-dialog-header {
      border-radius: 0px;
    }
  }
}
