/**
 * Generated from https://google-webfonts-helper.herokuapp.com/
 */

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/assets/fonts/Inter/inter-v11-latin-regular.woff2&display=swap') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/inter-v11-latin-regular.woff&display=swap') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('/assets/fonts/Inter/inter-v11-latin-600.woff2&display=swap') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/inter-v11-latin-600.woff&display=swap') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/assets/fonts/Inter/inter-v11-latin-700.woff2&display=swap') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/inter-v11-latin-700.woff&display=swap') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-500 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('/assets/fonts/Inter/inter-v11-latin-500.woff2&display=swap') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/inter-v11-latin-500.woff&display=swap') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/assets/fonts/Montserrat/montserrat-v24-latin-regular.woff2&display=swap') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/montserrat-v24-latin-regular.woff&display=swap') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('/assets/fonts/Montserrat/montserrat-v24-latin-600.woff2&display=swap') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/montserrat-v24-latin-600.woff&display=swap') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
