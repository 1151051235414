// Global branded styling. App specific styling should NOT go in this file
body {
  font-family: 'Open Sans', sans-serif;
  color: #484f59;
  background-color: var(--surface-a);
}
.p-container {
  max-width: 1280px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.p-container-fluid {
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.intro-banner {
  background: linear-gradient(0deg, rgba(32, 95, 146, 0.3), rgba(32, 95, 146, 0.3)),
    url(https://s3-alpha-sig.figma.com/img/c236/f6eb/10e4ed39cd1fb032a2c2e12949cae5aa?Expires=1650844800&Signature=XmF6IDWguBwvWjp1T~~-6Da5OQ--W57pa9ozsHvP29gFtGcCtPUCajRpy50WzDmhc41~Xg4AGjsjpvvnt~ADvEugV0VP~cinZHuxOriKInOsZd5BVfjxu-texY~ZJisz3jvltKvup5rpAW9rk8eRmv2oEjP7GB-YIgWqPhHO9A8I87zaMNFwZdDCrxUqmuZAUfrnTr1ab7P6izJBU2fkNjuuVDSLA8wf99ef6nl18BrEyQTxYePkbRDOW2KprjsejpV033q2U1VOadTqPnCP-m1XYYT7z6rPiFPfjqUtTllKsw0C02nQcDg7EB9P7yRVN2muKzdoSLqkfDz0R75xDw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)
      no-repeat;
  background-size: cover;
  background-position: center;
}
