.p-message,
.p-inline-message {
  padding: 0.75rem;
  margin: $inlineMessageMargin;
  border-radius: 4px;
  justify-content: flex-start !important;

  &.p-message-success,
  &.p-inline-message-success {
    .pi-check:before {
      content: '\e90a'; // pi-check-circle
    }
  }

  &.p-message-error,
  &.p-inline-message-error {
    .pi-times-circle:before {
      content: '\e989'; // pi-exclamation-circle
    }
  }
}
.p-message {
  .p-message-wrapper {
    padding: 0;
  }
}
