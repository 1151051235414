// Global branded styling. App specific styling should NOT go in this file
a {
  &.button-link {
    cursor: pointer;
    color: $primaryBlueDark;
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
  &.disabled,
  &.disabled .fa {
    color: dimgray !important;
    cursor: not-allowed !important;
    border-color: #a7a7a7 !important;
    background-color: #ededed !important;
  }
  &.grey {
    color: #a2a2a2 !important;
  }
  &.small {
    font-weight: 500 !important;
  }
}
