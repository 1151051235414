@import '/src/styles/mixins';
@import '/src/styles/theme/variables';

.nts-table,
p-table {
  margin-bottom: 4rem;
  .nts-table,
  p-table {
    margin-bottom: 0;
  }
  tr,
  td {
    color: #737578;
  }

  .p-datatable .p-datatable-tbody {
    & > tr {
      &:hover {
        background-color: #f2f2f3;
      }
    }
    & > tr > td {
      border-width: 0px 0px 1px 0px;
    }
  }
  .p-button.p-button-text.p-button-plain:enabled:hover {
    background: initial;
  }
  @include lg-down {
    tr,
    td {
      color: #000;
    }

    .p-datatable.p-datatable-responsive-stack {
      .p-datatable-thead {
        display: none;
      }
      .p-datatable-tbody {
        & > tr {
          &:hover {
            background: initial;
          }
          & > td {
            justify-content: initial;
            &:hover {
              background: initial;
            }
          }
        }
      }

      .p-datatable-tbody > tr td {
        justify-content: initial !important;
        padding: 0.25rem 0rem;
        border-bottom: 2px solid #dee2e6;
        font-weight: bold;
        display: block !important;
        & > * {
          display: inline !important;
        }
        a {
          text-decoration: none;
        }
        &:before {
          font-weight: initial;
          color: #000;
          float: none !important;
          margin-right: 7px;
          content: attr(data-label) ': ';
        }
      }
      .p-datatable-tbody > tr td:first-child {
        padding-top: 25px;
      }
      .p-datatable-tbody > tr td:last-child {
        border-bottom-width: 2px;
        padding-bottom: 25px;
        padding-top: 15px;
        font-size: 14px;
        &:before {
          display: none;
        }
      }
      .p-datatable-tbody .groupheader td {
        font-weight: initial;
      }
    }
  }
}

.single-stack .nts-table {
  @include lg-down {
    .p-datatable-tbody > tr td:last-child {
      padding: 0.25rem 0 1rem 0 !important;
    }
    .p-datatable-tbody > tr td:last-child:before {
      display: inline !important;
    }
  }
}
