.p-selectbutton {
  &.p-buttonset {
    background: $toggleButtonBg;
    border-radius: $borderRadius;
    .p-button {
      padding: 0.5rem 0.75rem;
      @include md-up {
        padding: 0.5rem 1rem;
      }
      .p-button-label {
        font-weight: 400;
      }
      &:focus {
        box-shadow: none;
      }
      border-radius: $borderRadius !important;
    }
  }
}
