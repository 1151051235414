$border: 1px solid $gray-200 !important;
.p-accordion {
  .p-accordion-header {
    margin: 0px;
    .p-accordion-header-link {
      padding: 2rem 1.25rem;
      border-top: $border;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      border-radius: 0 !important;
      @include h6;
      @include md-up {
        @include h4;
      }
      &:focus {
        box-shadow: none !important;
      }
      .p-accordion-toggle-icon {
        order: 2;
        margin-left: auto;
        &.pi-chevron-right::before {
          content: '\e903'; // Chevron up
        }
      }
    }
  }
  .p-accordion-content {
    padding: 1rem 0;
    border: none;
    border-radius: 0 !important;
    @include md-up {
      padding: 1.5rem;
    }
  }

  p-accordiontab {
    &:last-child {
      .p-accordion-header-link {
        border-bottom: $border;
      }
      .p-accordion-content {
        border-bottom: $border;
      }
    }
  }
}
