/***** Global Mixins *****/
@import '/src/styles/styleguide/typography_mixins';
@import 'theme/variables/breakpoints';
@import '/src/styles/designer/mixins';
// Easing Animations
// https://matthewlein.com/tools/ceaser
// USAGE:  .anim{ transition: 0.7s $easeInOutBack; }
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

@mixin convert($list) {
  @each $name, $value in $list {
    --nts-#{$name}: #{$value};
  }
}

@mixin transition($time: 0.3s) {
  transition: $time ease-out;
}

// Show/hide DOM elements without actually removing them from the DOM
@mixin hidden() {
  opacity: 0;
  position: fixed;
  z-index: -1;
  visibility: hidden;
  height: 0px;
  overflow: hidden;
}

@mixin visible() {
  opacity: 1;
  position: initial;
  z-index: 5;
  visibility: visible;
  height: initial;
  overflow: initial;
}

@mixin icon-size($size: 1rem, $height: 1rem, $width: 1rem) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin p-button-icon-style($size: null, $fontWeight: null, $color: null, $hoverColor: null) {
  ::ng-deep {
    .p-button-icon {
      font-size: $size;
      font-weight: $fontWeight;
      color: $color;
    }
  }
  &:hover {
    ::ng-deep {
      .p-button-icon {
        color: $hoverColor;
      }
    }
  }
}

@mixin p-button-svg-icon-style($size: null, $color: null, $hoverColor: null) {
  ::ng-deep {
    .p-button-icon {
      height: $size;
      background-color: $color;
    }
  }
  &:hover {
    ::ng-deep {
      .p-button-icon {
        background-color: $hoverColor;
      }
    }
  }
}

@mixin setup-svg-mask($path, $width: 18, $height: 12, $color: $textColor) {
  -webkit-mask-image: url($path);
  mask-image: url($path);
  -webkit-mask-size: cover;
  mask-size: cover;
  height: 0.75rem;
  aspect-ratio: calc($width / $height);
  background-color: $color;
}

//Gradients
@mixin gradient($startColor, $endColor, $direction: to bottom) {
  background: $endColor;
  background-image: linear-gradient($direction, $startColor 0%, $endColor 100%);
}
@mixin gradient-radial($startColor, $endColor) {
  background: $endColor;
  background-image: radial-gradient(ellipse at center, $startColor 0%, $endColor 100%);
}

//Use a gradient as a border
//Vertical
@mixin gradient-border-vert($mainColor) {
  border-color: $mainColor;
  border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $mainColor, rgba(255, 255, 255, 0)) 1 100%;
}
//Horizontal
@mixin gradient-border-horz($mainColor) {
  &::after {
    height: 1px;
    width: 100%;
    content: '';
    display: block;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, $mainColor 50%, rgba(0, 0, 0, 0) 100%);
  }
}

//Generate sprite
@mixin sprite($pos, $offset) {
  background-position: 0px -#{$pos * $offset}px;
}

// Sizing mixins
@mixin sm-up {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: $md) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: $md) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin lg-down {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin xl-down {
  @media (max-width: $xl) {
    @content;
  }
}

@mixin xxl-up {
  @media (min-width: $xxl) {
    @content;
  }
}

@mixin xxl-down {
  @media (max-width: $xxl) {
    @content;
  }
}

@mixin background-color-image-opacity($color, $image, $opacity, $backgroundPosition: center, $backgroundAttachemnt: scroll) {
  position: relative;
  background: $color;
  z-index: 1;
  &:before {
    content: ' ';
    z-index: -1;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: $opacity;
    background: url($image) no-repeat;
    background-size: cover;
    background-position: $backgroundPosition;
    background-attachment: $backgroundAttachemnt;
  }
}

@mixin active-input {
  &:enabled:hover,
  &:enabled:focus {
    border-width: 2px;
    margin: -1px; // remove margin for larger border
  }
}

@mixin input-form-validation($parentSelectorWithoutNgDirty) {
  // If our form doesn't have .show-errors-on-submit, append .ng-dirty and validation will behave like normal
  form:not(.show-errors-on-submit):not(.show-errors-on-touched) {
    #{$parentSelectorWithoutNgDirty}.ng-dirty {
      @content;
    }
  }

  // If our form has .show-errors-on-touched, append .ng-touched and errors will show when inputs are touched
  form.show-errors-on-touched {
    #{$parentSelectorWithoutNgDirty}.ng-touched {
      @content;
    }
  }

  // If our form has .show-errors-on-submit, nest the content inside ng-submitted to allow
  // showing errors on submit.
  form.show-errors-on-submit {
    &.ng-submitted {
      #{$parentSelectorWithoutNgDirty} {
        @content;
      }
    }
    // For special fields within form that are validated immediately when dirty. e.g. passwords
    #{$parentSelectorWithoutNgDirty}.show-dirty-error.ng-dirty {
      @content;
    }
  }
}

/*
 * Custom breakpoint handling for the carousel.
 * Works along with the p-carousel [responsiveOptions] with the same breakpoints.
 * Include mixin on the p-carousel element selector
 */
@mixin responsive-carousel-fixed-width($cardWidth, $cardMargin: 42px, $maxCards: 10, $singleCardPeekMargin: 2.5rem) {
  $cardTotalWidth: $cardWidth + $cardMargin;

  $carouselButtonWidth: 45px;
  $carouselSpacing: $carouselButtonWidth * 2;

  display: block;
  width: 100%;
  ::ng-deep {
    $singleCardCarouselWidth: $cardWidth + $cardMargin + $carouselSpacing;

    /* At smallest view, hide the next/prev arrows and add right margin to "peek" at the next card */
    .p-carousel-items-container {
      margin-right: $singleCardPeekMargin;
    }
    .p-carousel-prev,
    .p-carousel-next {
      display: none;
    }
    /* At the first breakpoint, show the next/prev arrows and remove right margin */
    @media (min-width: $singleCardCarouselWidth) {
      .p-carousel-items-container {
        margin-right: 0rem;
      }
      .p-carousel-prev,
      .p-carousel-next {
        display: flex;
        flex: 1;
      }
    }

    /* Set a custom breakpoint for every number of cards */
    @for $i from 1 to $maxCards + 1 {
      $cardsWidth: $cardTotalWidth * $i;
      $carouselWidth: $cardsWidth + $carouselSpacing;
      @media (min-width: $carouselWidth) {
        .p-carousel-items-content {
          max-width: $carouselWidth - $carouselSpacing;
        }
      }
    }
  }
}
