.p-listbox {
  .p-listbox-header {
    .p-checkbox {
      width: 100%;
      &::after {
        content: 'All';
        position: relative;
        bottom: 4px;
        left: 8px;
      }
    }
  }

  .p-listbox-list {
    .p-listbox-item {
      &.p-highlight {
        color: initial;
        background: initial;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
